import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest, select } from 'typed-redux-saga';
import isEqual from 'lodash-es/isEqual';
import {
  CleaningWidgetsFleetDashboardActions,
  IGetCleaningListWithOperatingTimeRequestAction,
  IGetCleaningListWithOperatingTimeSuccessAction,
  IGetCleaningListWithOperatingTimeErrorAction,
} from './cleaningWidgetsFleetDashboardActions';
import { CleaningWidgetsFleetDashboardSelectors } from './cleaningWidgetsFleetDashboardSelectors';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { MachinesListWithCleaningReport } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { LoadingBarActions } from 'app/cross-cutting-concerns/loading-bar/state/loadingBarSlice';

export function* getCleaningListWithOperatingTimeSaga(
  action: IGetCleaningListWithOperatingTimeRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<MachinesListWithCleaningReport>
  | PutEffect<IGetCleaningListWithOperatingTimeSuccessAction>
  | PutEffect<IGetCleaningListWithOperatingTimeErrorAction>
  | PutEffect<
      ReturnType<typeof LoadingBarActions.showLoadingBar> | ReturnType<typeof LoadingBarActions.hideLoadingBar>
    >,
  void,
  IDependencies
> {
  const cachedData = yield* select(CleaningWidgetsFleetDashboardSelectors.selectData);
  // if (cachedData) {
  //   yield* put(LoadingBarActions.showLoadingBar());
  // }

  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.listMachinesWithOperatingTime, action.payload);
    if (!isEqual(response.machines.data, cachedData)) {
      yield* put(CleaningWidgetsFleetDashboardActions.getCleaningListWithOperatingTimeSuccess(response));
    }
  } catch (error) {
    yield* put(
      CleaningWidgetsFleetDashboardActions.getCleaningListWithOperatingTimeError({
        error,
      })
    );
  }
  // finally {
  //   if (cachedData) {
  //     yield* put(LoadingBarActions.hideLoadingBar());
  //   }
  // }
}

export function* cleaningWidgetsFleetDashboardSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(
    CleaningWidgetsFleetDashboardActions.GET_CLEANING_LIST_WITH_OPERATING_TIME_REQUEST,
    getCleaningListWithOperatingTimeSaga
  );
}
